
export const APP_VERSION = "UAT 1.0";
export const BASE_URL_JWT ='https://fulcrumone-sso-service.uat.lms.fulcrumone.net';
export const BLOB_BASE_URL ='https://fulcrumone-fileuploadblobstorage-service.uat.lms.fulcrumone.net'
export const PDF_GENERATOR_BASE_URL ='https://pdfgenerator-svc.fulcrumone.net/api'
export const LMS_GENERIC_API_BASE_URL = 'https://fulcrumone-dynamicform-service.uat.lms.fulcrumone.net'
export const GENERATE_TOKEN =  `${BASE_URL_JWT}/api/generateToken`;
export const REFRESH_TOKEN = `${BASE_URL_JWT}/api/token/refresh`;
export const REVOKE = `${BASE_URL_JWT}/api​/token​/revoke`;
export const LMS_BASE_URL = "https://lms-activitymanagement.uat.lms.fulcrumone.net"
export const CHECKIN_URL = "https://lms-conveyanceexpensemanagement.uat.lms.fulcrumone.net/api/";
export const  LOCATION_BASE_URL = `https://api.opencagedata.com/geocode/v1/json?q=`
export const BASE_URL_CALENDAR = 'https://lms-calendermanagement.uat.lms.fulcrumone.net/api/';
export const BASE_URL_CONVEYANCE = 'https://lms-conveyanceexpensemanagement.uat.lms.fulcrumone.net/api';
export const BASE_URL_LEAD = "https://lms-leadmangement.uat.lms.fulcrumone.net/api/";
export const BASE_URL_ACTIVITY = "https://lms-activitymanagement.uat.lms.fulcrumone.net/api/";
export const BASE_URL_PEHLA_KADAM = 'https://lms-abhpehlakadammanagement.uat.lms.fulcrumone.net/api/';

export const NOTIFICATION_BASE_URL = 'https://fulcrumone-pushnotifications-service.uat.lms.fulcrumone.net';
export const DELETE_LEAD_DRAFTLIST_BY_ID = 'https://lms-activitymanagement.uat.lms.fulcrumone.net/api/'+ 'deleteleadactivity'
export const SIMPLIFY_AZURE = 'https://lmssimplifycreateapi-uat.azurewebsites.net/api';
export const LEAD_PRIORITY_BASE_URL = 'https://lmsscoringprioritymodelapi-uat.azurewebsites.net';
export const LMS_HRMS = 'https://lmshrms-uat.azurewebsites.net/api';
export const DASHBOARD_BASE_URL = 'https://lms-dashboardmanagement.uat.lms.fulcrumone.net';
export const FOLLOW_UP_BASE_URL = 'https://lms-followupmanagement.uat.lms.fulcrumone.net';
export const CHANGE_PASSWORD = `${BASE_URL_JWT}/api/changepassword`;
export const FORGOT_PASSWORD = `${BASE_URL_JWT}/api/ForgotPasswordWithCode`;
export const FORGOT_PASSWORD_VERIFY_CODE = `${BASE_URL_JWT}/api/ChangePasswordWithCode`;
export const GET_SUBORDINATES_LIST = `${BASE_URL_JWT}/api/getsubordinatedetails/`;
export const SMS_NOTIFICATION_BASE_URL = `https://fulcrumone-notifications-service.uat.lms.fulcrumone.net/api/`;
export const SIMPLIFY_LOGIN_URL = 'https://dcuatwfe04.indiafirstlife.com/SimplifyOnline/Login';
export const REPORT_BASE_URL = "https://fulcrumone-reporting-service.uat.lms.fulcrumone.net";
// block files urls
export const INSERT_BLOB_FILE = `${BLOB_BASE_URL}/api/insertfile`
export const INSERT_CAMPAIGN_BLOB_FILE = `${BLOB_BASE_URL}/api/banca/uploadcampaignlead`
export const INSERT_PERSISTENCY_BLOB_FILE = `${BLOB_BASE_URL}/api/banca/uploadpersistencylead`
export const INSERT_CAMPAIGN_ACTIVITY_BLOB_FILE = `${BLOB_BASE_URL}/api/banca/uploadcampaignactivities`

export const INSERT_AGENCY_CAMPAIGN_BLOB_FILE = `${BLOB_BASE_URL}/api/agency/uploadcampaignlead`
export const INSERT_AGENCY_PERSISTENCY_BLOB_FILE = `${BLOB_BASE_URL}/api/agency/uploadpersistencylead`
export const INSERT_AGENCY_CAMPAIGN_ACTIVITY_BLOB_FILE = `${BLOB_BASE_URL}/api/agency/uploadcampaignactivities`

export const INSERT_CSC_CAMPAIGN_BLOB_FILE = `${BLOB_BASE_URL}/api/csc/uploadcampaignlead`
export const INSERT_CSC_PERSISTENCY_BLOB_FILE = `${BLOB_BASE_URL}/api/csc/uploadpersistencylead`
export const INSERT_CSC_CAMPAIGN_ACTIVITY_BLOB_FILE = `${BLOB_BASE_URL}/api/csc/uploadcampaignactivities`

export const LIST_CAMPAIGN_BLOB_FILES = `${BLOB_BASE_URL}/api/listfiles/`
export const CAMPAIGN_BLOB_FILE_DOWNLOAD = `${BLOB_BASE_URL}/api/downloadfile/`

//export const CREATE_USER_ADMIN = BASE_URL_JWT +'/api/CreateAdminUserWithCode';
// Generic endpoints
export const GET_MASTER_DATA = `${LMS_GENERIC_API_BASE_URL}/api/getdropdowndata/`;
export const GET_MASTER_DATA_BY_CHANNEL_ID = `${LMS_GENERIC_API_BASE_URL}/api/getsingledropdowndatabychannel/`;
export const ADD_DYNAMIC_DATA = `${LMS_GENERIC_API_BASE_URL}/api/adddynamicdata/`;
export const UPDATE_DYNAMIC_DATA = `${LMS_GENERIC_API_BASE_URL}/api/updatedynamicdata/`;
export const UPDATE_PERSISTANCY_DATA = `${LMS_GENERIC_API_BASE_URL}/api/UpdatepersistencyLead/`;

export const GET_BDMBYRBH_LIST = `${LMS_BASE_URL}/api/getbdmbyrbhid/`

// Activity Endpoints
export const CREATE_ACTIVITY = `${LMS_BASE_URL}/api/`;
export const UPDATE_ACTIVITY = `${LMS_BASE_URL}/api/`;
export const VALIDATE_ACTIVITY = `${LMS_BASE_URL}/api/validatemeetingdate/`
export const DISTRICT_LIST = `${LMS_BASE_URL}/api/getdistrictdropdown/`
export const GET_SRM_LIST = `${LMS_BASE_URL}/api/getsrmbybranch`;
export const GET_ACTIVITIES_FOR_HNI = `${LMS_GENERIC_API_BASE_URL}/api/getactivityforHNI/`;


export const GET_ACTIVITY_BY_ID = `${BASE_URL_ACTIVITY}getactivitivitybyid/`;
export const GET_CAMPAIGN_ACTIVITY_BY_ID_CSC = `${BASE_URL_ACTIVITY}getcampaignactivitybyid/`;
export const GET_ACTIVITY_HISTORY_BY_ID = `${BASE_URL_ACTIVITY}gethistoricalactivitydata/`;
export const GET_CAMPAIGN_ACTIVITY_HISTORY_BY_ID = `${BASE_URL_ACTIVITY}gethistoricalcampaignactivities/`;
export const GET_ACTIVITIES_BY_USER_CALENDAR = `${BASE_URL_ACTIVITY}getactivitybyuserid/`;
export const GET_ACTIVITIES_BY_USER = `${BASE_URL_ACTIVITY}getlistofactivitiesbyuserid/`;
export const GET_CAMPIAGN_ACTIVITIES_BY_USER = `${BASE_URL_ACTIVITY}getlistofcampaignactivitiesbyuserid`;
export const GET_ACTIVITIES_BY_USER_DRAFT = `${BASE_URL_ACTIVITY}getlistofactivitiesbyuserid`;
export const DELETE_ACTIVITIES_BY_USER_DRAFT = `${BASE_URL_ACTIVITY}deleteleadactivity`;
export const FINISH_DAY_ACTIVITY = `${BASE_URL_CONVEYANCE}/finishdayactivity/`;
export const IS_FINISH_DAY_DONE = `${BASE_URL_CONVEYANCE}/checkfinishdaystatus/`;
export const GET_UPDATED_STATUS_CALL_COUNT = `${BASE_URL_ACTIVITY}getupdatestatuscallcount/16/`;
export const GET_SMA_REGION_MAP_BY_USERID = `${BASE_URL_ACTIVITY}getsmaregionmapping/`;
export const GET_BRANCH_BY_ACTIVITY_ID = `${BASE_URL_LEAD}getbranchdetailsbyactivityid/`;



export const GET_BRANCH_DETAILS = `${BASE_URL_LEAD}getuserbranchdetails`;
export const GET_LG_SP_DETAILS = `${BASE_URL_LEAD}getlgspbybranch`;
export const VALIDATE_LEAD_MOBILE = `${BASE_URL_LEAD}validatenewleadmobile`;
export const GET_ACTIVITY_BY_USER_ID = `${BASE_URL_LEAD}getmappedactivitiesbyuserid`;
export const GET_CAMPAIGN_ACTIVITY_BY_USER_ID = `${BASE_URL_LEAD}getactivitiesforcampaignfilter`;
export const VALIDATE_METING = `${BASE_URL_LEAD}validatemeetingdate`;
export const GET_LEAD_BY_USERID = `${BASE_URL_LEAD}getleadsbyuserid`;
export const GET_LEAD_BY_ID = `${BASE_URL_LEAD}getleadbyid`;
export const VALIDATE_MEETING_DATE = `${BASE_URL_LEAD}validatemeetingdate`;
export const VALIDATE_CHECKIN = `${CHECKIN_URL}getcheckinstatus`;
export const GET_PERSISTANCY_LEAD_BY_USERID = `${BASE_URL_LEAD}Getallpersistencyleads`;
export const GET_PERSISTANCY_LEAD_BY_ID = `${BASE_URL_LEAD}getpersistencyleadbyid`
export const GET_COMPAIGN_LEAD_BY_ID = `${BASE_URL_LEAD}getcampaignleadbyid`
export const GET_CAMPAIGN_LEAD_BY_USER_ID = `${BASE_URL_LEAD}getallcampaignleads`
export const GET_CAMPAIGN_LEAD_FILTER = `${BASE_URL_LEAD}campaignleadfilter`
export const GET_CAMPAIGN_NAME_FOR_FILTER = `${BASE_URL_LEAD}getcampaignnamesforfilter`
export const GET_SRM_DETAILS = `${LMS_BASE_URL}/api/getsrmbybranch`;



export const UPDATE_LEAD_SATUS_BY_ID = `${BASE_URL_LEAD}updateleadstatus`;
export const GET_LEAD_STATUS_ACTION = `${BASE_URL_LEAD}getleadactionidmapping`;
export const GET_TEAM_LIST= `${BASE_URL_LEAD}getleadsforassignments`;
export const GET_FILTER_LEAD_BY_USERID = `${BASE_URL_LEAD}getlistofleadsbyuserid`;
export const GET_LEAD_HISTORY= `${BASE_URL_LEAD}getleadhistory`;
export const GET_LEADLIST_BY_USER = `${BASE_URL_LEAD}getleadsbyuserid`;
export const GET_MPH_NAME_LIST = `${LMS_GENERIC_API_BASE_URL}/api/getcorporatebranchcodelatlong`;

//Leadpoint EndPoints
export const CREATE_LEAD = `${LMS_BASE_URL}/api/`;
export const UPDATE_LEAD = `${LMS_BASE_URL}/api/`;
export const CREATE_LEAD_DEDUPE_VALIDATION_URL = `${BASE_URL_LEAD}leaddedupevalidation`


// sync end points
export const PULL_LEAD = `${LMS_GENERIC_API_BASE_URL}/api/sync/pullleads`;
export const PUSH_LEAD = `${LMS_GENERIC_API_BASE_URL}/api/sync/pushlead`;
export const PULL_ACTIVITY = `${LMS_GENERIC_API_BASE_URL}/api/sync/pullactivities`;
export const PUSH_ACTIVITY = `${LMS_GENERIC_API_BASE_URL}/api/sync/pushactvity`;
export const PULL_HISTORICAL_LEADS = `${LMS_GENERIC_API_BASE_URL}/api/sync/pullhistoricalleads`
export const PULL_HISTORICAL_ACTIVITY = `${LMS_GENERIC_API_BASE_URL}/api/sync/pullhistoricalactivities`
export const PULL_HISTORICAL_CAMPAIGN_ACTIVITY = `${LMS_GENERIC_API_BASE_URL}/api/sync/pullhistoricalcampaignactivities/`



//Calendar Endpoints

export const GET_CALEDNDER_LEAD_ACTIVTIES_BY_USER = `${BASE_URL_CALENDAR}getcalendardata?UserId=`;
export const GET_USER_CHECK_IN_COUNT = `${BASE_URL_CONVEYANCE}/getusercheckincount`;

//CONVEYANCE Endpoints
export const CONVEYANCEEXPENSEMANAGEMENT_BASE_URL = `${BASE_URL_CONVEYANCE}/checkinactivity`;
// SIMPLIFY CREATE
export const SIMPLIFY_CREATE_URL = `${SIMPLIFY_AZURE}/LmsSimplifyCreateApi`;
export const LEAD_PRIORITY_URL = `${LEAD_PRIORITY_BASE_URL}${'/api/LeadScoring'}`;
export const FUNCTIONS_KEY_SIMPLIFY_CREATE = '42ucrQ2byvp860wTdiX048rhhi4B1_JvppgyXHVx8PZhAzFur02Nsw==';
export const LMS_HRMS_CHECKIN_URL = `${LMS_HRMS}/LmsHrms`;
export const FUNCTIONS_KEY_CHECKIN = '9r9LOd9qArYjNZD3z4f-tikeGN0vzvucTfbvrMRL2oofAzFunhxk_g==';
export const FUNCTIONS_KEY_LEAD_PRIORITY = 'nA5ufa86fTARg3kOMpN9oJA7SmPedUW0o5ITfvfGsvgDAzFuZesZQQ==';
export const DIRECTION_KEY = "IFlR@P#9$4V!N31AX";
export const LMS_DEVICE_CODE = "1194";
export const IN = "0";
export const OUT = "1";

// Dashboard endpoints
export const ACCOUNT_OVERVIEW = `${DASHBOARD_BASE_URL}${'/api/GetAccountOverviewByUserID'}`
export const PERFORMANCE_STATS_OVERVIEW = `${DASHBOARD_BASE_URL}${'/api/getleadsbyperformancestatscount'}`
export const GET_ACTIVITY_OVERVIEW_COUNT = `${DASHBOARD_BASE_URL}/api/GetActivityOverviewCount/`
export const GET_CAMPAIGN_ACTIVITY_OVERVIEW = `${DASHBOARD_BASE_URL}${'/api/getcampaignactivityoverview'}`
export const GET_ACTIVITY_OVERVIEW_COUNT_GRAPH_DATA = `${DASHBOARD_BASE_URL}/api/getactivityoverview/`
export const USER_INFORMATION = `${DASHBOARD_BASE_URL}${'/api/UserInformation/'}`
export const UPDATE_USER_HOME_LOCATION= `${DASHBOARD_BASE_URL}${'/api/UpdateUserLocation'}`
export const GetLeadOverviewCount = `${DASHBOARD_BASE_URL}${'/api/GetLeadOverviewCount/'}`
export const BDM_LIST = `${DASHBOARD_BASE_URL}${'/api/myTeam/'}`
export const TEAM_LIST = `${DASHBOARD_BASE_URL}${'/api/getdashboardloggedindetail/'}`

export const SUBORDINATE_BY_USER_ID_AND_LEVEL = `${BASE_URL_JWT}${'/api/getsubordinatedetailsbylevel/'}`

export const GetLeadOverview = `${DASHBOARD_BASE_URL}${'/api/getleadoverview'}`
export const GET_RECRUITMENT_LEAD_OVERVIEW = `${DASHBOARD_BASE_URL}${'/api/getrecruitmentleadoverview'}`
export const GET_PERSISTANCY_LEAD_FILTER = `${BASE_URL_LEAD}persistencyleadfilter`;
export const USER_PLAN_LIST = `${FOLLOW_UP_BASE_URL}${'/api/getuserplansbydate'}`
export const GET_CAMPAIGN_LEAD_CAMPAIGN_NAMES = `${DASHBOARD_BASE_URL}/api/GetCampaignLeadCampaignNames`
export const GET_CAMPAIGN_DASHBOARD_OVERVIEW_DATA = `${DASHBOARD_BASE_URL}/api/getcampaignleadoverview`
export const GetPersistancyLeadOverview = `${DASHBOARD_BASE_URL}${'/api/getpersistenceleadoverview'}`
export const GET_ACTIVITY_TYPE_DETAILS_COUNTS_BY_USER_ID = `${DASHBOARD_BASE_URL}${'/api/getactivitytypedetailscountsbyuserid'}`


//persistency leads
export const GET_ALL_PERSISTENCY_LEADS=`${BASE_URL_LEAD}getallpersistencyleads`
export const PERSISTENCY_LEAD_FILTER = `${BASE_URL_LEAD}persistencyleadfilter`
export const VALIDATE_PERSISTENCY_LEAD_FOR_UPDATE = `${BASE_URL_LEAD}validatepersistencyleadforupdate`

//pehlaKadam

export const PEHLA_KADAM_CHECKLIST =`${BASE_URL_PEHLA_KADAM}getuserchecklist`
export const PEHLA_KADAM_SAVE_CHECKLIST =`${BASE_URL_PEHLA_KADAM}saveuserchecklistresponse`
// export const PEHLA_KADAM_BDM_CHECKLIST =`${BASE_URL_PEHLA_KADAM}getcheckliststatus`
export const PEHLA_KADAM_BDM_CHECKLIST =`${BASE_URL_PEHLA_KADAM}getchecklistbyuserlevel`

export const PEHLA_KADAM_DASHBORD_OVERVIEW = `${DASHBOARD_BASE_URL}${'/api/getdashboardpehlakadamdata'}`
export const PEHLA_KADAM_EXPORT_DATA =`${BASE_URL_PEHLA_KADAM}exportdata`
export const PEHLA_KADAM_BDM_VIEW =`${BASE_URL_PEHLA_KADAM}getbdmchecklistanswer`
export const PEHLA_KADAM_RBH_CHECKLIST =`${BASE_URL_PEHLA_KADAM}getcheckliststatusbyrbhuserid`
export const PEHLA_KADAM_EXPORT_DATA_FOR_RBH =`${BASE_URL_PEHLA_KADAM}rbhexportdata`
export const PEHLA_KADAM_QUS_GRID_LIST =`${BASE_URL_PEHLA_KADAM}getchecklistgriddata`

//ReAssignLead

export const GET_BDM_BY_BRANCHID = `${BASE_URL_LEAD}getleadsforassignments`
export const REASSIGN_LEADS = `${BASE_URL_LEAD}reassignlead`
export const PERSISTENCY_ACTIVITY_FILTER = `${BASE_URL_LEAD}getactivitiesforpersistencyfilter/`
//notification
export const GET_ALL_NOTIFICATION_BY_USER = `${NOTIFICATION_BASE_URL}/api/getallnotificationsbyuser/`
export const GET_NOTIFICATION_BY_USER = `${NOTIFICATION_BASE_URL}/api/getnotificationsbyuser/`
export const POST_READALL_NOTIFICATION_BY_USER = `${NOTIFICATION_BASE_URL}/api/readallnotifications/`
export const POST_READ_SINGLE_NOTIFICATION_BY_USER = `${NOTIFICATION_BASE_URL}/api/readnotification/`
export const VERSION_INFO_URL = `${DASHBOARD_BASE_URL}/api/getapplicationversion`;

//send sms twilio
export const SEND_SMS_TWILIO = `${SMS_NOTIFICATION_BASE_URL}sendsmstwilio`

//search lead
export const SEARCH_LEAD = `${BASE_URL_LEAD}searchlead`

//agencyMapping
export const GET_AGENCY_USER_MAPPING = `${BASE_URL_ACTIVITY}getagencyadvisormapping`
export const DOWNLOAD_IMAGE_URL = `${BLOB_BASE_URL}/api/viewfileasbase64`
// SSO
export const SIMPLIFY_APP_BASE_URL = 'https://dcuatwfe04.indiafirstlife.com'
export const SIMPLIFY_SSO_TOKEN = 'U2ltcGxpZnlAMjAyMg=='
export const SIMPLIFY_WEB_APP_URL = `${SIMPLIFY_APP_BASE_URL}${'/Simplifyonline/Login?'}`
export const SIMPLIFY_ANDROID_APP_URL = 'com.indiafirstlife.simplify://SimplifyModule/Login?'
export const HYPERCOLLATERAL_PAGE = "https://dcuatwfe04.indiafirstlife.com/SimplifyOnline/HyperpersonalisedCollateralGeneration?";
export const HYPER_COLLATRAL_TOKEN = '31EE08FD-6C2C-4A56-97B0-788FB67E2CC6'

//Admin module
export const CAMPAIGN_LEAD_LISTING =`${BASE_URL_LEAD}getallcampaignleadslistbychannel/`
export const CAMPAIGN_ACTIVITY_LISTING =`${BASE_URL_LEAD}getallactivitieslistbychannel/`
export const SALES_MANAGMENT_ACTIVITY_LISTING =`${BASE_URL_LEAD}getallsmactivitieslistbychannel/`
export const PERSISTANCY_LEAD_LISTING =`${BASE_URL_LEAD}getallpersistencyleadlistbychannel/`
export const BASE_URL_ESTIMATE = 'https://estimator-service.fulcrumone.net';
export const CONVERT_TO_EXCEL= BASE_URL_ESTIMATE+'/api/ConvertToExcel';
export const BUSSINESS_LEAD_LISTING =`${BASE_URL_LEAD}getallbusinessleadslistbychannel/`
export const GET_NH_LIST = `${BASE_URL_LEAD}getnhlistfilter`;
export const GET_FILTER_LIST_BY_LEVEL = `${BASE_URL_LEAD}getlistfilterbylevel`;
export const DOWNLOAD_BUSINESS_LIST= `${BASE_URL_LEAD}downloadallbusinessleadsbychannel/`
export const DOWNLOAD_PERSISTENCY_LIST= `${BASE_URL_LEAD}downloadallpersistencyleadsbychannel/`
export const DOWNLOAD_CAMPAIGN_LIST =`${BASE_URL_LEAD}downloadallcampaignleadsbychannel/`
export const DOWNLOAD_CAMPAIGNACTIVITY_LIST=`${BASE_URL_LEAD}downloadallactivitiesbychannel/`
export const DOWNLOAD_BROCA_ACTIVITY_LIST=`${BASE_URL_LEAD}downloadbrocamisreportforactivitiesbychannel`
export const DOWNLOAD_BROCA_LEAD_LIST=`${BASE_URL_LEAD}downloadbrocaleadsbychannel`
export const GET_CAMPAIGN_NAME =`${BASE_URL_LEAD}getcampaignname/`
export const GET_CAMPAIGN_ACTIVITY_BY_ID = `${BASE_URL_ACTIVITY}getcampaignactivitybyid/`
export const CSC_CAMPAIGN_ACTIVITY_LISTING =`${BASE_URL_LEAD}getallcampaignactivitieslistbychannel/`
export const DOWNLOAD_CSC_CAMPAIGNACTIVITY_LIST=`${BASE_URL_LEAD}downloadcampaignactivitiesbychannel/`
export const LOGG_INFO = `${LMS_GENERIC_API_BASE_URL}/api/InsertGoogleLog`;
export const DOWNLOAD_ABHSMAACTIVITY_LIST=`${BASE_URL_LEAD}downloadadminsmactivitycsvdata/`
export const DOWNLOAD_BRANCH_PARTNER_AND_CATEGORYWISE = `${BASE_URL_LEAD}downloadbranchpartnerandcategorywisecsvfile/`;
export const DOWNLOAD_MUST_DO_ACTIVITY_SUMMARY = `${BASE_URL_LEAD}downloadbrocareportmustdoactivitysummarycsvfile/`;
export const DOWNLOAD_BUSINESS_DEVELOPMENT_ACTIVITY = `${BASE_URL_LEAD}downloadbrocareportbusinessdevelopmentactivitycsvfile`;
export const DOWNLOAD_BFD_WISE_ACTIVITY = `${BASE_URL_LEAD}downloadbrocareportbfdwiseactivitysummarycsvfile`;
export const DOWNLOAD_PARTNER_ENGAGEMENT_ACTIVITY = `${BASE_URL_LEAD}downloadbrocareportpartnerengagementactivitysummarycsvfile`;
export const DOWNLOAD_LEAD_GENERATION_ACTIVITY = `${BASE_URL_LEAD}downloadbrocareportleadgenerationactivitysummarycsvfile`;
export const DOWNLOAD_GCL_BUSINESS_LIST= `${BASE_URL_LEAD}downloadallgclbusinessleadsbychannel/`
export const DOWNLOAD_GCL_ACTIVITY_LIST=`${BASE_URL_LEAD}downloadallgclactivitybychannel/`

// csv acivity end point
export const  GET_ACTIVITY_BY_CSV_FILE=`${REPORT_BASE_URL}/api/downloadactivitycsvfile`;
export const  GET_LEAD_BY_CSV_FILE=`${REPORT_BASE_URL}/api/downloadleadscsvfile`;

// partner detail
// export const GET_PARTNER_DETAILS=`${LMS_BASE_URL}/api/getpartnerdetailsbybranchcode`
export const GET_PARTNER_DETAILS=`${LMS_GENERIC_API_BASE_URL}/api/getbrocapartnername`
export const SET_COLLATERAL_FLAG=`${BASE_URL_LEAD}processcollateralinfo`
export const GET_OTP_FOR_RESET_DEVICE=`${BASE_URL_JWT}/api/generateotp`
export const VALIDATE_OTP_FOR_RESET_DEVICE=`${BASE_URL_JWT}/api/validateotp`
export const VALIDATE_USER_RESET_DEVICE=`${DASHBOARD_BASE_URL}/api/verifydevice`